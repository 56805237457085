<template>
  <h1>CORSE</h1>
  <el-row justify="center" style="margin-top: 60px;">
    <el-col :span="5" >
      <el-card :body-style="{ padding: '0px' }">
        <img
          :src="require('../assets/passati.png')"
          class="image"
        />
        <div style="padding: 14px">
          <span><b>Storico ordini</b></span>  
          <hr style="background-color: rgb(231,228,58);height: 1px; margin-top: 7px;margin-bottom: 7px;width: 40%; margin-left:86px;" align="right">
          <span>Ordini conclusi</span>
          <div class="bottom" style="margin-top: 10px">
          <router-link :to="{ path: `/corse/corse_passate` }" class="routerLink">
            <el-button size="small">
              <el-icon><DArrowRight /></el-icon>
            </el-button>
          </router-link>
        </div>
        </div>
      </el-card>
    </el-col>
    <el-col :span="5" :offset="1">
      <el-card :body-style="{ padding: '0px' }">
        <img
        :src="require('../assets/attivi.png')"
          class="image"
        />
        <div style="padding: 14px">
          <span><b>Ordini Live</b></span>  
          <hr style="background-color: rgb(231,228,58);height: 1px; margin-top: 7px;margin-bottom: 7px;width: 40%; margin-left:88px;" align="right">
          <span>Prenotazioni in corso</span>
          <div class="bottom" style="margin-top: 10px">
          <router-link :to="{ path: `/corse/corse_attive` }" class="routerLink">
            <el-button size="small">
              <el-icon><DArrowRight /></el-icon>
            </el-button>
           </router-link> 
        </div>
        </div>
      </el-card>
    </el-col>
    <el-col :span="5" :offset="1">
      <el-card :body-style="{ padding: '10px' }">
        <img
          :src="require('../assets/futuri.png')"
          class="image"
        />
        <div style="margin-top: 10px;">
          <span><b>Agenda</b></span>  
          <hr style="background-color: rgb(231,228,58);height: 1px; margin-top: 7px;margin-bottom: 7px;width: 40%; margin-left:90px;" align="right">
          <span>Prenotazioni future</span>
          <div class="bottom" style="margin-top: 10px">
          <router-link :to="{ path: `/corse/corse_future` }" class="routerLink"> 
            <el-button size="small">
              <el-icon><DArrowRight /></el-icon>
            </el-button>
          </router-link>
        </div>
        </div>
      </el-card>
    </el-col>
  </el-row>
</template>

<script>
import { Check, Delete, Edit } from "@element-plus/icons-vue";
export default {
  name: "CorsePage",
  components: {},
  setup() {
    //viene eseguito prima di caricare la pagina, qui richiama le api (da quello che metti su per esempio ../services/detailsAPI)

    return {
      Check,
      Delete,
      Edit,
    };
  },
};
</script>

<style>
h1 {
  margin-top: 40px;
}
  
</style>
