<template>
    <br>
      <h1>Corse passate</h1> <br> <br> <br> 
    
      <div class="flex flex-wrap items-center">
        <el-text class="driver" type="info">Seleziona una sede per visualizzare i booking attivi </el-text>
        <el-cascader v-model="input" :options="headquartersId" :props="props1" clearable @change="handleChange_headquarter"  />
      </div>
    
      <div class="top">
        <el-table :data="homeTable" :header-cell-style="{ background: 'rgb(231,228,58)'}">
          <el-table-column prop="id" label="N° booking" >
            <template #default="scope">
              <el-input :value="scope.row.id" />
            </template>
          </el-table-column>
          <el-table-column prop="sede" label="Sede" width="300"/>
          <el-table-column prop="customer" label="Cliente" width="200"/>
          <el-table-column prop="stato" label="Stato corsa"  width="200" />
          <el-table-column prop="auto" label="Auto" width="200"/>
          <el-table-column prop="targa" label="Targa"  width="100"/>
          <el-table-column prop="orario_arrivo" label="Orario arrivo"  width="150"/>
          <el-table-column prop="orario_rilascio" label="Orario rilascio"  width="150"/>
          <el-table-column prop="orario_effettivo" label="A/R"  width="60"/>
          <el-table-column width="50" >
            <template #default="scope">
              <router-link :to="{ path: `/corse/corse_passate/dettagli/${scope.row.id}` }" class="routerLink">
                <el-button size="small">
                  <el-icon><DArrowRight /></el-icon>
                </el-button>
              </router-link>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </template>
    
    <script>
    // Qui importi quello che ti serve
    import { ref, computed } from "vue";
    import bookingAPI from '../services/bookingAPI.js';
    const dayjs = require("dayjs");

    export default {
      name: 'HomePage',
      components: {},
      setup() {
        let headquartersId= ref([]);
        const headquarters = ref([]);
        let tableData = ref([]);
        const input = ref("");
    
        const loadHeadquarters = async () => {
        try {
          const response = await bookingAPI.headquarter();
          console.log(response.data)
          response.data.forEach((headquarter) => {
           let tableRow={
              value: headquarter.id,
              label: `${headquarter.address} ${headquarter.street_number}, ${headquarter.city}` 
            }
            headquartersId.value.push(tableRow);
            });
           } catch(e) {
          console.log(e);
          }
        };
    
        loadHeadquarters();
    
        const handleChange_headquarter = async (val) => {
          try {
            tableData = ref([]);
            const response = await bookingAPI.getBookingPassati(val);

            response.data.forEach((booking) => {
              let tableRow = {
                id: booking.booking_id,
                sede: booking.partner_point.address + ' ' + booking.partner_point.street_number + ', ' + booking.partner_point.city,
                customer: booking.customer_name + ' ' + booking.customer_surname,
                stato: booking.state,
                auto: booking.car.marca + ', ' + booking.car.modello,
                targa: booking.car.targa,
                orario_arrivo:  dayjs(booking.arrivo_date).format('DD/MM/YYYY HH:mm'),
                orario_rilascio:  dayjs(booking.rilascio_date).format('DD/MM/YYYY HH:mm'),
                orario_effettivo: " "+dayjs(booking.true_gone_partner_date).add(2,'hour').format('HH:mm')+"\n "+ dayjs(booking.true_return_partner_date).add(2,'hour').format('HH:mm'),
              };

              tableRow.stato=state(tableRow.stato);
              tableData.value.push(tableRow);
            });
    
          } catch(e) {
            console.log(e);
          }
        }

    const state = (state)=>{
      switch (state) {
      case 'before':
        state= "Non ancora iniziato"
      break;
      case 'jolly_driver_atPartner1':
        state="Jolly e driver presso la sede";
      break;
      case 'driver_atCustomer1' :
      state='Driver arrivato dal cliente';
      break;
      case 'driver_scanner_customer1':
        state="Il cliente ha consegnato l'auto"
        break;
      case 'take_picture_atCustomer1':
         state="Il driver ha fatto le foto"
        break;
      case 'driver_atPartner1':
         state="Driver arrivato in concessionaria"
        break;
      case 'driver_atPartner2':
         state="Driver arrivato in concessionaria"
      break;
      case 'driver_atCustomer2':
         state="Driver arrivato dal cliente"
      break;
      case 'driver_scanner_customer2':
        state="Il cliente ha riconsegnato l'auto"
        break;
      case 'take_picture_atCustomer2':
         state="Il driver ha fatto le foto"
        break;
      case 'jolly_atCustomer2':
         state="Concluso"
        break;
      case 'end':
         state="Rifiutato"
        break;
        }
return state;
    }
    
         const homeTable = computed(function () {
          return tableData.value.filter((row) =>
            row.id.toLowerCase().includes(input.value.toLowerCase())
          );
        });
    
        handleChange_headquarter();
    
        return {
          tableData,
          homeTable,
          loadHeadquarters,
          headquartersId,
          headquarters,
          state,
          handleChange_headquarter
        };
      },
    };
    </script>
    
    <style>
    .el-table {
      color: black;
    }
    .el-table thead {
      color: black;
    }
    .el-table td {
      color: black;
      background-color:rgb(241, 241, 241);
    }
    
    .example-showcase .el-dropdown + .el-dropdown {
      margin-left: 15px;
    }
    .example-showcase .el-dropdown-link {
      cursor: pointer;
      color: var(--el-color-primary);
      display: flex;
      align-items: center;
    }
    .top {
      margin-top: 50px;
    }
    </style>
    