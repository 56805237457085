<template>
  <div style="margin-top: 50px">
    <h1>Dettagli del booking {{ booking.date }}</h1>
  </div>

  <hr
    style="
      height: 4px;
      color: rgb(231,228,58);
      background-color: rgb(231,228,58);
      width: 35%;
      margin-top: 15px;
      margin-left: 32.5%;
    "
  >

  <el-row type="flex" style="margin-top: 5%; margin-left:12%">
    <el-col :span="5" style="margin-top: 5px">
      <span>Stato della prenotazione </span>
    </el-col>
    <div v-if="accepted==true">
      <el-col :span="3" style="margin-top: 5px">
        <el-tag class="ml-2" type="success" effect="dark"> Accettata dal cliente</el-tag>
      </el-col>
    </div>
    <div v-else-if="accepted==false">
      <el-col :span="3" style="margin-top: 5px">
        <el-tag class="ml-2" type="danger" effect="dark"> In attesa di conferma</el-tag>
      </el-col>
    </div>
  </el-row>

  <div class="marg_form" justify="center" style="margin-left: 150px; margin-right: 150px; margin-top: 2%">
    <el-row type="flex">
      <el-col :span="3" style="margin-top: 52px; margin-left: -4px">
        <span>Cliente</span>
      </el-col>
      <el-col :span="4">
        <el-input v-model="booking.customer" readonly="readonly" />
      </el-col>
      <el-col :span="3" style="margin-top: 52px; margin-left: 10px">
        <span>Cell. cliente</span>
      </el-col>
      <el-col :span="4">
        <el-input v-model="booking.customer_number" readonly="readonly" />
      </el-col>
    </el-row>
    <el-row type="flex">
      <el-col :span="3" style="margin-top: 52px; margin-left: -4px">
        <span>Auto</span>
      </el-col>
      <el-col :span="4">
        <el-input v-model="booking.auto" class="w-50 m-2" readonly="readonly" />
      </el-col>

      <el-col :span="3" style="margin-top: 52px; margin-left: 10px">
        <span>Targa</span>
      </el-col>
      <el-col :span="4">
        <el-input v-model="booking.targa" class="w-50 m-2" readonly="readonly" />
      </el-col>
    </el-row>

    <el-row type="flex" style="margin-top: 5%">
      <el-col :span="4" style="margin-top: 10px; margin-left: 15px">
        <el-icon> <LocationInformation /></el-icon>
        <span>Punto di prelievo</span>
      </el-col>
      <el-col :span="7" style="margin-top: 5px; margin-left: 5px">
        <el-input v-model="booking.pick_point" readonly="readonly" />
      </el-col>
      <el-col :span="6" style="margin-top: 5px">
        minuti andata: {{booking.min_andata}}
      </el-col>
    </el-row>

    <el-row type="flex" style="margin-top: 3%">
      <el-col :span="4" style="margin-top: 10px; margin-left: 15px">
        <el-icon><OfficeBuilding /></el-icon>
        <span>Sede del Partner</span>
      </el-col>
      <el-col :span="7" style="margin-top: 5px; margin-left: 5px">
        <el-input v-model="booking.partner_point" readonly="readonly" />
      </el-col>
    </el-row>

    <el-row type="flex" style="margin-top: 3%">
      <el-col :span="4" style="margin-top: 10px; margin-left: 20px">
        <el-icon> <LocationInformation /></el-icon>
        <span>Punto di consegna</span>
      </el-col>
      <el-col :span="7" style="margin-top: 5px;">
        <el-input v-model="booking.leave_point" readonly="readonly" />
      </el-col>
      <el-col :span="6" style="margin-top: 5px">
        minuti ritorno: {{booking.min_ritorno}}
      </el-col>
    </el-row>
<br>
    <el-divider/>
    <el-row type="flex" style="margin-top: 3%; margin-left: 2%">
      <el-col :span="3" style="margin-top: 10px">
        <span>Driver andata</span>
      </el-col>
      <el-col :span="4" style="margin-top: 5px">
        <el-input v-model="booking.driver_andata" readonly="readonly" />
      </el-col>
      <el-col :span="3" style="margin-top: 10px; margin-left: 40px">
        <span>Telefono driver</span>
      </el-col>
      <el-col :span="4" style="margin-top: 5px">
        <el-input v-model="booking.telephone_gone_driver" readonly="readonly" />
      </el-col>
    </el-row>

    <el-row type="flex" style="margin-top: 5%; margin-left: 2%">
      <el-col :span="3" style="margin-top: 10px">
        <span>Driver ritorno</span>
      </el-col>
      <el-col :span="4" style="margin-top: 5px; ">
        <el-input v-model="booking.driver_ritorno" readonly="readonly" />
      </el-col>
      <el-col :span="3" style="margin-top: 10px; margin-left: 40px">
        <span>Telefono driver</span>
      </el-col>
      <el-col :span="4" style="margin-top: 5px">
        <el-input v-model="booking.telephone_return_driver" readonly="readonly" />
      </el-col>
    </el-row>


  
    <el-row type="flex" style="margin-top: 2%; margin-left: 30px">
      <el-col :span="24">
        <div class="demo-collapse">
          <el-collapse v-model="activeName" accordion>
            <!--<el-collapse-item
              title="Cliccare per visualizzare i dettagli dei lavori da svolgere nel booking:"
              name="1"
            >-->
            <el-collapse-item
              name="1"
            >
           
              <template #title> 
                <div class="collapse-title">
                  Cliccare per visualizzare i dettagli dei lavori da svolgere nel booking
                </div>
              </template>
           
              <div v-for="(task, counter_min) in booking.tasks" v-bind:key="counter_min">
                <div v-if="task.pay == true" style="margin-bottom: 3%" >
                  <el-input v-model="task.description" autosize readonly="readonly" >
                    <template #append> {{ task.cost + " €" }} {{ "pagamento in app"}} </template>
                  </el-input>
                </div>
                <div v-if="task.pay == false" style="margin-bottom: 3%" >
                  <el-input v-model="task.description" autosize readonly="readonly">
                    <template #append> {{ task.cost + " €" }} {{ "pagamento NON in app"}} </template>
                  </el-input>
                </div>
              </div>
              <el-input v-model="booking.title" autosize readonly="readonly">
                <template #append> {{ booking.tot_cost + " €" }} </template>
              </el-input>
            </el-collapse-item>
          </el-collapse>
        </div>
      </el-col>
    </el-row>
  </div>
  </template>
  
  <script>
  import { ref } from "vue";
  import bookingAPI from "../services/bookingAPI.js";
  import { useRoute } from "vue-router";
  import { reactive } from "vue";
  const dayjs = require("dayjs");
  export default {
    name: "HomePage",
    components: {},
    setup() {
      const accepted = ref(false);
      const route = useRoute();
      const bookingId = route.params.id.split("-")[0];
      const tableData = ref([]);
      const activeName = ref('1')
      const booking = reactive({
        date: "",
        customer: "",
        customer_number:"",
        task_payed:false,
        simba_payed: false,
        auto: "",
        targa: "",
        tasks: [],
        driver_andata: "",
        telephone_gone_driver:"",
        telephone_return_driver:"",
        driver_ritorno: "",
        min_andata:0,
        min_ritorno:0,
        pick_point:"",
        leave_point:"",
        partner_point:"",
        title: "Costo totale dei lavori",
        tot_cost: 0,
      });
  
      const loadBooking = async () => {
        try {
          const response = await bookingAPI.getBooking(route.params.id);
          booking.date= dayjs(response.data.datePartner_gone).format('DD/MM/YYYY');
          booking.customer =
            response.data.customer.name + " " + response.data.customer.surname;
          booking.customer_number=
            response.data.customer.telephone_number;
          booking.task_payed= response.data.task_payed;
          booking.simba_payed = response.data.simba_payed;

          booking.auto = response.data.car.marca + ", " + response.data.car.modello;
          booking.targa = response.data.car.targa;

          response.data.gone_driver !=null ?
            booking.driver_andata=response.data.gone_driver.name + " " + response.data.gone_driver.surname: booking.driver_andata="Non assegnato";

          response.data.return_driver != null ? 
            booking.driver_ritorno=response.data.return_driver.name + " " + response.data.return_driver.surname : booking.driver_ritorno="Non assegnato";

          response.data.gone_driver != null ? 
             booking.telephone_gone_driver=response.data.gone_driver.telephone_number: booking.telephone_gone_driver=null ;

          response.data.return_driver != null ? 
            booking.telephone_return_driver=response.data.return_driver.telephone_number: booking.telephone_return_driver=null;

          booking.min_andata= response.data.simba_minuteGone ;
          booking.min_ritorno=response.data.simba_minuteReturn ;
          booking.partner_point=
            response.data.headquarter.address+ " "+  response.data.headquarter.street_number+ ", "+ response.data.headquarter.cap+ " "+ response.data.headquarter.city + " "+ response.data.headquarter.province;
          
          if(response.data.points[0].pick_point == true) 
          { 
            //console.log("pointsss", response.data.points[0])
            booking.pick_point = response.data.points[0].address+ " " + 
                                 response.data.points[0].street_number + ", "+
                                 response.data.points[0].cap + " "+
                                 response.data.points[0].city + " "+
                                 response.data.points[0].province;

            booking.leave_point = response.data.points[1].address+ " " + 
                                 response.data.points[1].street_number + ", "+
                                 response.data.points[1].cap + " "+
                                 response.data.points[1].city+ " "+
                                 response.data.points[1].province;
          }else{
            
            booking.leave_point = response.data.points[0].address+" "+
                                  response.data.points[0].street_number +", "+  
                                  response.data.points[0].cap + " "+
                                  response.data.points[0].city + " "+
                                  response.data.points[0].province;

            booking.pick_point = response.data.points[1].address+ " " + 
                                 response.data.points[1].street_number + ", "+
                                 response.data.points[1].cap + " "+
                                 response.data.points[1].city+ " "+
                                 response.data.points[1].province;

          }
          
          booking.tasks = response.data.tasks;

          console.log("taskkk", booking.task_payed)
           console.log("simba", booking.simba_payed)

          if(booking.task_payed===true && booking.simba_payed===true){
            accepted.value=true;
          }else {
             accepted.value=false;
          }

          booking.tasks.forEach((task) => {
            booking.tot_cost = booking.tot_cost + task.cost;
          });

          return booking;
        } catch (e) {
          console.log(e);
        }
      };
  
      loadBooking();
  
      return {
        tableData,
        bookingId,
        booking,
        activeName,
        accepted
      };
    },
  };
  </script>
  
  <style>
  .margin {
    margin-top: 30px;
  }
  hr {
    margin-top: 20px;
  }
  .marg_form {
    margin-top: 100px;
  }
  .el-input-group__append {
    background: transparent;
  }

  .collapse-title{
    font-size: 18px;
    font-weight: bold;
  }

  .demo-border .line {
   width: 100%;
  height: 0;
  border-top: 1px solid var(--el-border-color);
}
  </style>
  