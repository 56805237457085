import API from './API';
import axios from 'axios';

export default {

  getCustomers() {
    return API().get('/customer');
  },
  getCars(customer_id) {
    return API().post('/car/car', customer_id);
  },

  getHeadquarters(){
    return API().get('/employee/headquarters')
  },

  calculateMinute(points){
    return API().post('/booking/calculate-minute', points)
  },

  addBooking(booking) {
    return API().post('/booking/create', booking);
  },

  upload(file, mail) {
    return axios.post('https://gestionalesimba.it/booking/upload/' + mail, file, {
      headers: {
        'Content-Type': 'multipart/form-data',
        "Authorization": "Bearer " + localStorage.getItem("jwt") 
      },
    });
  },

  mailCustomer(mail){
    return API().get('/booking/mailCustomer/'+mail)
  },

  addTask(task){
    return API().post('/task', task)
  }

}