<template>
  <div style="margin-bottom: 30px">
    <h1>PRENOTAZIONE</h1>
  </div>

  <el-space wrap>
    <el-card class="box-card">
      <template #header>
        <div class="card-header">
          Il cliente è nuovo? Registralo quì prima di prenotare
          <router-link class="routerLink" to="/addcustomer_employee">
            <el-icon style="vertical-align: middle" color="#409EFC" :size="20">
              <CirclePlus/>
            </el-icon>
          </router-link>
        </div>
      </template>
      <div v-if="active === 0">
        <el-form :model="booking">
          <el-row justify="center">
            <el-col :span="8">
              <el-cascader
                placeholder="Selezionare un cliente"
                :options="customersId"
                filterable
                :props="props1"
                clearable
                @change="handleChange_customer"
              />
            </el-col>
            <el-col :span="8">
              <el-cascader
                placeholder="Selezionare un'auto"
                :options="carsId"
                :props="props1"
                clearable
                @change="handleChange_Car"
              />
            </el-col>
            <el-col :span="8">
              <el-cascader
                placeholder="Selezionare una sede"
                :options="headquartersId"
                :props="props1"
                clearable
                @change="handleChange_headquarter"
              />
            </el-col>
          </el-row>
          <el-divider />
          <el-row :gutter="20" justify="center" >
            <el-col :sm="8" :xs="24">
              <el-form-item label="Consegna dell'auto">
                <el-date-picker
                  v-model="booking.datePartner_gone"
                  type="date"
                  placeholder="Seleziona data e ora"
                  format="DD/MM/YYYY"
                 :disabled-date="disabledDate"
                />
                 <!--<el-date-picker
                  v-model="booking.datePartner_gone"
                  type="date"
                  placeholder="Seleziona data e ora"
                  format="DD/MM/YYYY"
                />-->
              </el-form-item>
            </el-col>
            <el-col :sm="3" :xs="24">
              <el-cascader
                placeholder="Ora"
                :options="orario_andata"
                :props="props1"
                clearable
                @change="handleChange_orario_andata"
              />
            </el-col>
            <el-col :sm="1" :xs="24">
              <span class="text-gray-500">-</span>
            </el-col>
            <el-col :sm="8" :xs="24">
              <el-form-item label="Ritiro dell'auto" >
               <el-date-picker
                  v-model="booking.datePartner_return"
                  type="date"
                  placeholder="Seleziona data e ora"
                  format="DD/MM/YYYY"
                  :disabled-date="disabledDate_return"
                />
                 <!--<el-date-picker
                  v-model="booking.datePartner_return"
                  type="date"
                  placeholder="Seleziona data e ora"
                  format="DD/MM/YYYY"
                />-->
              </el-form-item>
            </el-col>
            <el-col :sm="3" :xs="24">
              <el-cascader
                placeholder="Ora"
                :options="orario_ritorno"
                :props="props1"
                clearable
                @change="handleChange_orario_ritorno"
              />
            </el-col>
          </el-row>
          <el-divider />
          <el-row :gutter="21">
            <el-col :span="8" style="margin: 0 0.85rem">
              <el-form-item label="Punto di prelievo" style="margin: 0 0.27rem">
                <el-input
                  v-model="booking.points[0].address"
                  label="Indirizzo"
                  placeholder="Indirizzo"
                />
              </el-form-item>
            </el-col>
            <el-col :span="3" style="margin: 0 0.1rem">
              <el-form-item>
                <el-input
                  v-model="booking.points[0].street_number"
                  label="N. civico"
                  placeholder="N. civico"
                />
              </el-form-item>
            </el-col>
            <el-col :span="3" style="margin: 0 0.5rem">
              <el-form-item>
                <el-input
                  v-model="booking.points[0].cap"
                  label="CAP"
                  placeholder="CAP"
                />
              </el-form-item>
            </el-col>
            <el-col :span="4" style="margin: 0 0.5rem">
              <el-form-item>
                <el-input
                  v-model="booking.points[0].city"
                  label="Città"
                  placeholder="Città"
                />
              </el-form-item>
            </el-col>
            <el-col :span="3" style="margin: 0 0.5rem">
              <el-form-item>
                <el-input
                  v-model="booking.points[0].province"
                  label="Provincia"
                  placeholder="Provincia"
                />
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="21">
            <el-col :span="8" style="margin: 0 0.5rem">
              <el-form-item label="Punto di consegna" >
                <el-input
                  v-model="booking.points[1].address"
                  label="Indirizzo"
                  placeholder="Indirizzo"
                />
              </el-form-item>
            </el-col>
            <el-col :span="3" style="margin: 0 0.5rem">
              <el-form-item>
                <el-input
                  v-model="booking.points[1].street_number"
                  label="N. civico"
                  placeholder="N. civico"
                />
              </el-form-item>
            </el-col>
            <el-col :span="3" style="margin: 0 0.5rem">
              <el-form-item>
                <el-input
                  v-model="booking.points[1].cap"
                  label="CAP"
                  placeholder="CAP"
                />
              </el-form-item>
            </el-col>
            <el-col :span="4" style="margin: 0 0.5rem">
              <el-form-item>
                <el-input
                  v-model="booking.points[1].city"
                  label="Città"
                  placeholder="Città"
                />
              </el-form-item>
            </el-col>
            <el-col :span="3" style="margin: 0 0.5rem">
              <el-form-item>
                <el-input
                  v-model="booking.points[1].province"
                  label="Provincia"
                  placeholder="Provincia"
                />
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
        <el-row justify="center" :gutter="22" style="margin-top: 3%;" >
          <el-col :span="5" style="margin: 11px 3px"> 
            Minuti andata: {{response_minute_gone}}
          </el-col>
          <el-col :span="5" style="margin: 11px 3px"> 
            Minuti Ritorno: {{response_minute_return}}
          </el-col>
          <el-col :span="6">
            <div style="margin: -35px 3px">
              <el-button @click="calcola_min"> Calcola minuti</el-button>
            </div>
          </el-col>
        </el-row> 
        <el-divider/>
        <el-row :gutter="20" style="margin-top: -3%;">
          <el-col :span="7" >
            <el-form-item  label="Minuti residui del cliente">
              <el-input
                v-model="residual_minuteVal"
                label="Minuti rimasti"
                readonly="readonly" 
              />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row justify="center" style="margin-top: -1%;">
          <router-link class="routerLink" to="/">
            <el-button style="margin: 10px 3px" @click="annulla"> Annulla </el-button>
          </router-link>

          <el-button 
            :disabled="button1_disabled==true 
            || (booking.points[0].address=='')|| (booking.points[0].street_number=='') || (booking.points[0].cap=='')|| (booking.points[0].city=='') || (booking.points[0].province=='')
            || (booking.points[1].address=='')|| (booking.points[1].street_number=='') || (booking.points[1].cap=='')|| (booking.points[1].city=='') || (booking.points[1].province=='')" 
          style="margin: 10px 3px" @click="next">Avanti</el-button>
 <!--<el-button style="margin: 10px 3px" @click="next">Avanti</el-button>-->
        </el-row>
      </div>

      <div v-if="active === 1">
        <el-form :model="booking">
          <el-scrollbar height="220px">
            <el-row>
              <div
                v-for="(task, counter_task) in booking.tasks"
                v-bind:key="counter_task"
                width="100%"
              >
                <el-row :gutter="20">
                  <el-col :span="12">
                    <el-form-item label="Descrizione lavori">
                      <el-input v-model="task.description" />
                    </el-form-item>
                  </el-col>
                  <el-col :span="5">
                    <el-form-item label="Costo">
                      <el-input v-model="task.cost" />
                    </el-form-item>
                  </el-col>
                  <el-col :span="3">
                    <el-form-item label="Il cliente paga i lavori sull'app">
                      <el-switch v-model="task.pay" />
                    </el-form-item>
                  </el-col>
                </el-row>

                <el-button v-if="counter_task != 0"
                  type="danger"
                  :icon="Delete"
                  @click="rimuoviTask($event, counter_task)"
                  circle
                />
                <el-button @click="aggiungiTask" type="success" plain>Aggiungi lavori</el-button>
              </div>
            </el-row>
          </el-scrollbar>
          <el-divider/>
          
          <el-row :gutter="20">
            <el-col :span="15" style="margin-top: 1.5%;">
              <el-form-item label="Upload PDF" >
                <input
                  type="file"
                  accept="application/pdf"
                  multiple
                  @change="onFileSelected"
                >
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="15" style="margin-top: 1.5%;">
              <el-form-item>
                <el-input
                  v-model="booking.note"
                  :rows="5"
                  type="textarea"
                  placeholder="Aggiungi delle note per i driver"
                />
              </el-form-item>
            </el-col>
          </el-row>
          <!--<div style="margin-top: 10%;">-->

          <!--</div>-->

          <!-- <el-row :gutter="20" style="margin-top: 8%;">
            <el-divider/>
            <el-col :span="5" >
              <el-form-item  label="Minuti residui">
                <el-input
                  v-model="residual_minuteVal"
                  label="Minuti rimasti"
                  readonly="readonly" 
                />
              </el-form-item>
            </el-col>-->

          <!--<el-col :span="15">
              <el-form-item v-if="residual_minuteVal == 0" label="Il cliente pagherà il servizio simba tramite l'app">
                <el-radio-group v-model="booking.simba_pay"  @change="changeValue_simbaPay">
                  <el-radio label="true">Sì</el-radio>
                  <el-radio label="false">No</el-radio>
                </el-radio-group>
              </el-form-item>
            <el-row style="margin-top: -10px;">
            <el-form-item label="Il cliente pagherà il servizio simba tramite l'app">
              <el-switch v-model="booking.simba_pay" />
            </el-form-item>
            </el-col>
          </el-row>-->
          <el-row justify="center" style="margin-top: 3.5%;">
            <router-link class="routerLink" to="/">
              <el-button style="margin: 1px 3px" @click="annulla"> Annulla</el-button>
            </router-link>
            <router-link class="routerLink" to="/">
              <el-button :disabled="(booking.tasks[0].description=='')" style="margin: 1px 3px" @click="onSubmit"> Crea </el-button>
            </router-link>
          </el-row>
        </el-form>
      </div>
      <el-steps :active="active" finish-status="success" align-center>
        <el-step title="Step 1" />
        <el-step title="Step 2" />
      </el-steps>
    </el-card>
  </el-space>
</template>

<script>
import prenotazioneAPI from "../services/prenotazioneAPI.js";
import bookingAPI from "../services/bookingAPI.js";
import { reactive, ref, toRaw } from "vue";
import { ElMessage } from "element-plus";
import { Delete } from "@element-plus/icons-vue";
import { isEmpty } from 'lodash';
import customerAPI from "../services/customerAPI";
const dayjs = require("dayjs");

export default {
  name: "PrenotazioniPage",
  components: {},

  setup() {
    //const uploadRef = ref<UploadInstance>();
    const booking = reactive({
      datePartner_gone: "",
      datePartner_return: "",
      customer: {},
      car: {},
      driverGone: null,
      driverReturn: null,
      headquarter: {},
      status: "before",
      over_twentysix: false,
      simba_pay: true,
      imponibile: 0,
      tot_fattura: 0,
      rif_fattura: 0,
      esito: false,
      data:new Date(),
      points: [
        {
          order: 0,
          address: "",
          street_number: "",
          cap: "",
          city: "",
          province: "",
          pick_point: true,
          trip: null,
        },
        {
          order: 0,
          address: "",
          street_number: "",
          cap: "",
          city: "",
          province: "",
          pick_point: false,
          trip: null,
        },
      ],
      tasks: [{ description: "", cost: 0, pay: false, path:"string" }],
      pdf: "pdf",
      note:""
    });

    let active = ref(0);

    let button1_disabled=ref(true);
    let button2_disabled=ref(true);

    const next = () => {
        booking.datePartner_gone=dayjs(new Date(booking.datePartner_gone)).hour(val_date_andata).minute(0).second(0).format("YYYY-MM-DD HH:mm:ss");
        booking.datePartner_return=dayjs(new Date(booking.datePartner_return)).hour(val_date_ritorno).minute(0).second(0).format("YYYY-MM-DD HH:mm:ss");
        
      if(val_date_andata== 14){
          booking.datePartner_gone=dayjs(new Date(booking.datePartner_gone)).hour(val_date_andata).minute(30).second(0).format("YYYY-MM-DD HH:mm:ss");
       } 
      if(val_date_ritorno== 14){
        booking.datePartner_return=dayjs(new Date(booking.datePartner_return)).hour(val_date_ritorno).minute(30).second(0).format("YYYY-MM-DD HH:mm:ss");
       }

      if (active.value++ > 1) 
      {
        active.value = 0;
      }
    };

    let val_date_andata="";
    let val_date_ritorno="";
    let show_minute=true;

    const orario_andata = [
      { value: "8", label: "08:00" },
      { value: "9", label: "09:00" },
      { value: "10", label: "10:00" },
      { value: "11", label: "11:00" },
      { value: "12", label: "12:00" },
      { value: "14", label: "14:30" },
      { value: "15", label: "15:00" },
      { value: "16", label: "16:00" },
      { value: "17", label: "17:00" },
    ];


    const orario_ritorno = [
     { value: "10", label: "10:00" },
      { value: "11", label: "11:00" },
      { value: "12", label: "12:00" },
      { value: "14", label: "14:30" },
      { value: "15", label: "15:00" },
      { value: "16", label: "16:00" },
      { value: "17", label: "17:00" },
      { value: "18", label: "18:00" },
      { value: "19", label: "19:00" }
    ];  

    const tasks = [];
    const date_booking = ref(new Date());
    const datePartner_gone = ref(new Date());
    const datePartner_return = ref(new Date());
    const customersId = ref([]);
    const customers = ref([]);
    const driversId = ref([]);
    const drivers = ref([]);
    const partnersId = ref([]);
    const partners = ref([]);
    let carsId = ref([]);
    const cars = ref([]);
    let headquartersId = ref([]);
    const headquarters = ref([]);
    let page1 = true;
    let selectedFile1 = null;
    let fd = new FormData();

    const customerId = {
      customer_id: "",
    };

    let points = {
      departure: {},
      destination:{}
    };

    const response_minute_gone=ref(0);
    const response_minute_return=ref(0);

    let residual_minuteVal=ref(0);

    const payload={
        customer_id:"",
        partner_id: ""
    }

    const customersOptions = {};

    const props1 = {
      //checkStrictly: true,
      expandTrigger: 'hover'
    };

    const loadHeadquarters = async () => {
      try {
        const response = await bookingAPI.headquarter();
        response.data.forEach((headquarter) => {
          let tableRow = {
            value: headquarter.id,
            label: `${headquarter.address} ${headquarter.street_number} ${headquarter.city}`,
          };
          headquartersId.value.push(tableRow);
          headquarters.value.push(headquarter);
        });
      } catch {
        console.log("error");
      }
    };

    const loadCustomers = async () => {
      try {
        const response =  await customerAPI.getCustomer_all();
        response.data.forEach((customer) => {
           customer.forEach((customer_detail) => {
            if ((customer_detail.minutes.length ==0 || customer_detail.minutes[0].accepted === true)&& customer_detail.isAlive==true) {
          let tableRow = {
            value: customer_detail.id,
            label: `${customer_detail.name} ${customer_detail.surname}, ${customer_detail.mail}`,
          };
          customers.value.push(customer_detail);
          customersId.value.push(tableRow);
            }
        });
      
      })

      } catch {
        console.log("error");
      }
    };
    
    const loadCars = async (customer_id) => {
      try {
        const response = await prenotazioneAPI.getCars(customer_id);

        response.data.forEach((car) => {
          let tableRow = {
            value: car.id,
            label: `${car.marca} ${car.modello} ${car.targa}`,
          };
          carsId.value.push(tableRow);
          cars.value.push(car);

        });
      } catch {
        console.log("error");
      }
    };

    const handleChange_headquarter = async (val) => {
      booking.headquarter = headquarters.value.filter(
        (headquarter) => headquarter.id == val
      )[0];
      points.departure=booking.points[0];
      points.destination=booking.headquarter;
        //minute
      response_minute_gone.value= (await prenotazioneAPI.calculateMinute(points)).data;
      response_minute_gone.value=(response_minute_gone.value.min+ 0.10 *response_minute_gone.value.min).toFixed(0);

      points.departure=booking.headquarter;
      points.destination=booking.points[1];

      response_minute_return.value= (await prenotazioneAPI.calculateMinute(points)).data;
      response_minute_return.value=(response_minute_return.value.min+ 0.10 *response_minute_return.value.min).toFixed(0);

      checkInfo();
    };

    const handleChange_customer = async (val) => {
      carsId.value = [];
      booking.customer = customers.value.filter((customer) => customer.id == val)[0];

      booking.points[0].address=booking.customer.address;
      booking.points[0].street_number=booking.customer.street_number;
      booking.points[0].cap=booking.customer.cap;
      booking.points[0].city=booking.customer.city;
      booking.points[0].province=booking.customer.area;

      booking.points[1].address=booking.customer.address;
      booking.points[1].street_number=booking.customer.street_number;
      booking.points[1].cap=booking.customer.cap;
      booking.points[1].city=booking.customer.city;
      booking.points[1].province=booking.customer.area;

      customerId.customer_id = val[0];
      loadCars(customerId);
      loadMinutes(customerId);
      checkInfo();
    };

    const handleChange_Car = async (val) => {
      booking.car = cars.value.filter((car) => car.id == val)[0];
       checkInfo();
    };

    const handleChange_orario_andata = (val) =>{
       val_date_andata=val[0];
      checkInfo();
    }

    const handleChange_orario_ritorno = (val) =>{
      val_date_ritorno=val[0];
       checkInfo();
    }

    /*const changeValue_simbaPay = (val) =>{
      
      booking.simba_pay=val;
      return val;
    }*/

    const loadMinutes = async (customerId) => {
      try{
        const partner_response= await customerAPI.partner();

        payload.customer_id=customerId.customer_id;
        payload.partner_id=partner_response.data.id;

        const response = await customerAPI.getMinute_info(payload);

        if(response.data===null){
          show_minute=false;
          residual_minuteVal.value=0;
        }else{
        residual_minuteVal.value=response.data.residual_minute;
        }

        }catch(error){
          console.log(error)
        }
    }

    //upload
    const onFileSelected = (e) => {
      console.log(e.target.files)
        for(let file of e.target.files)
          { 
            selectedFile1 = file;
            fd.append('files', selectedFile1, selectedFile1.name);
          }
      return fd;
    };
    
    const fileLoad = async (fd) => {
      try {
        const mail = booking.customer.mail;
        await prenotazioneAPI.upload(fd, mail).then((resp) => {
          if (resp.status == 201) {
            console.log("riuscito");
          }
        });
      } catch (e) {
        console.log(e);
      }
    };

    const rimuoviTask = (e, counter) => {
      booking.tasks.splice(counter, 1);
      e.preventDefault();
    };

    const aggiungiTask = (e) => {
      booking.tasks.push({
        description: "",
        cost: 0,
        pay: false,
        path:"string"
      });
      e.preventDefault();
    };

    const addBookingNew = async (booking) => {
      let response = {};
      try {
        console.log(booking)
        response = await prenotazioneAPI.addBooking(booking);
        return response;
        } catch (error) {
          ElMessage({
            message: "Errore response!",
            type: "error",
          });
        console.log(error);
      }
    };

    const onSubmit = async () => {

      if (residual_minuteVal.value !== 0) {booking.simba_pay=true} else {booking.simba_pay=false}
      //if(booking.simba_pay==="true") {booking.simba_pay=true} else  if(booking.simba_pay==="false") {booking.simba_pay=false}
      console.log(JSON.stringify(toRaw(booking)))
      console.log(JSON.stringify(toRaw(booking)))

      addBookingNew(toRaw(booking)).then(async (response) => {
        if (response.status === 200) {
          if (selectedFile1!==null) 
          {
            await fileLoad(fd);}
          else{
            await prenotazioneAPI.mailCustomer(booking.customer.mail);
            }

          ElMessage({
            message: "Booking aggiunto!",
            type: "success",
          });
          console.log("Booking aggiunto!");
        } else {
          ElMessage({
            message: "Errore creazione booking!",
            type: "error",
          });
          console.log("errore inatteso");
        }
      });
    };
    
    const checkInfo=()=>{
      if(
        isEmpty(toRaw(booking.customer)) ||  isEmpty(toRaw(booking.car)) || isEmpty(toRaw(booking.headquarter))
        || val_date_andata=="" || val_date_ritorno=="")
        {
        button1_disabled.value=true;
    
        }else{

          button1_disabled.value=false;

        }
    }

    const changePage = () => {
          page1 = false;
    };

    const calcola_min = async () =>{
      points.departure=booking.points[0];
      points.destination=booking.headquarter;
        //minute
      response_minute_gone.value= (await prenotazioneAPI.calculateMinute(points)).data;
      response_minute_gone.value=Math.ceil(response_minute_gone.value.min+ 0.10 *response_minute_gone.value.min);

      points.departure=booking.headquarter;
      points.destination=booking.points[1];

      response_minute_return.value= (await prenotazioneAPI.calculateMinute(points)).data;
      response_minute_return.value=Math.ceil(response_minute_return.value.min+ 0.10 *response_minute_return.value.min);
    }

    //loadMinutes();
    loadCustomers();
    loadHeadquarters();
    checkInfo();

    function disabledDate(vDate){
      let expirydate = new Date();
        return vDate < expirydate.setDate(expirydate.getDate() + 5);
    }

    function disabledDate_return(vDate){
      let expirydate = new Date();
      //return vDate < expirydate.setDate(expirydate.getDate() + 5);
      
      return booking.datePartner_gone!="" ? vDate <booking.datePartner_gone: vDate < expirydate.setDate(expirydate.getDate() + 5);
    }

    return {
      calcola_min,
      //changeValue_simbaPay,
      show_minute,
      onFileSelected,
      active,
      next,
      page1,
      tasks,
      customersOptions,
      loadCars,
      addBookingNew,
      customerId,
      customers,
      customersId,
      booking,
      drivers,
      driversId,
      partners,
      partnersId,
      carsId,
      headquartersId,
      headquarters,
      onSubmit,
      props1,
      date_booking,
      datePartner_return,
      datePartner_gone,
      handleChange_Car,
      handleChange_customer,
      handleChange_headquarter,
      aggiungiTask,
      rimuoviTask,
      changePage,
      Delete,
      disabledDate,
      disabledDate_return,
      orario_andata,
      orario_ritorno,
      handleChange_orario_andata,
      handleChange_orario_ritorno,
      button1_disabled,
      button2_disabled,
      val_date_andata,
      val_date_ritorno,
      residual_minuteVal,
      response_minute_gone,
      response_minute_return,
    };


  
  },
       
};
</script>

<style>
.box-card {
  width: 1000px;
  height: 690px;
}
.el-steps {
margin-left: 30%;
margin-right: 30%;
margin-top: 2%;
}
</style>