import API from './API';

export default {
  getEmployee(id) {
    return API().get('/employee/info/' + id)
  },

updEmployee(employee) {
    return API().patch('/employee/' + employee.id, employee)
  },

  updPassword(password_obj) {
    return API().post('/updatePassword' ,password_obj)
  },
};