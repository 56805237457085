<template>
  <h1>Dettagli del cliente {{ customer.name }} {{ customer.surname }}</h1>

  <el-form :model="customer">
    <el-row  :gutter="50" >
      <el-col :sm="5" :xs="24">
        <el-form-item label="Id">
          <el-input v-model="customer.id" readonly="readonly" />
        </el-form-item>
      </el-col>

      <el-col :sm="6" :xs="24">
        <el-form-item label="Nome">
          <el-input v-model="customer.name" />
        </el-form-item>
      </el-col>

      <el-col :sm="6" :xs="24">
        <el-form-item label="Cognome">
          <el-input v-model="customer.surname" />
        </el-form-item>
      </el-col>
      <el-col :sm="7">
        <el-form-item label="Data di nascita">
          <el-date-picker
            v-model="customer.date_birth"
            type="date"
            placeholder="Pick a date"
            format="DD/MM/YYYY"
            style="width: 100%"
          />
        </el-form-item>
      </el-col>
    </el-row>

    <el-row  :gutter="50" >
    
    <el-col :sm="5">
      <el-form-item label="Mail">
        <el-input v-model="customer.mail" />
      </el-form-item>
    </el-col>
    <el-col :sm="6">
      <el-form-item label="Numero di telefono">
        <el-input
          v-model="customer.telephone_number"
        />
      </el-form-item>
    </el-col>
    <el-col :sm="5">
      <el-form-item label="CF">
        <el-input v-model="customer.CF" />
      </el-form-item>
    </el-col>
    <el-col :sm="8" style="min-height: 36px;">
      <el-form-item label="P.iva">
        <el-input v-model="customer.piva" />
      </el-form-item>
    </el-col>
    </el-row>

    <el-row :gutter="50">
      <el-col :sm="6">
        <el-form-item label="Indirizzo">
          <el-input v-model="customer.address" />
        </el-form-item>
      </el-col>
      <el-col :sm="5">
        <el-form-item label="Città">
          <el-input v-model="customer.city" />
        </el-form-item>
      </el-col>
      <el-col :sm="3" :xs="10">
        <el-form-item label="N. civico">
          <el-input v-model="customer.street_number" />
        </el-form-item>
      </el-col>
      <el-col :sm="5">
        <el-form-item label="Provincia">
          <el-input v-model="customer.area" />
        </el-form-item>
      </el-col>
      <el-col :sm="4">
        <el-form-item label="CAP">
          <el-input v-model="customer.cap" />
        </el-form-item>
      </el-col>
    </el-row>

    <el-row :gutter="50">
      <el-col :sm="8">
        <el-form-item label="Ragione sociale">
          <el-input v-model="customer.business_name" />
        </el-form-item>
      </el-col>
      <el-col :sm="8">
        <el-form-item label="SDI">
          <el-input v-model="customer.sdi" />
        </el-form-item>
      </el-col>
      <el-col :sm="8">
        <el-form-item label="pec">
          <el-input v-model="customer.pec" />
        </el-form-item>
      </el-col>
    </el-row>
    <el-row justify="center" class="salva" >
      <el-col :span="3">
        <el-form-item>
          <router-link :to="{ path: `/` }" class="routerLink">
          <el-button type="primary" :plain="true" @click="update"> Salva </el-button>
        </router-link>
          <router-link :to="{ path: `/customer` }" class="routerLink">
            &nbsp;
            <el-button> Annulla </el-button>
          </router-link>
        </el-form-item>
      </el-col>
    </el-row>

    <el-card>
      <h4> Minuti gratuiti assegnati al cliente </h4>
      <el-row :gutter="50" justify="center">
        <el-col :span="4">
          <el-form-item label="minuti">
            <el-input v-model="minute.minute" readonly="readonly"/>
          </el-form-item>
        </el-col>
        <el-col :span="4">
          <el-form-item label="minuti residui">
            <el-input v-model="minute.residual_minute" readonly="readonly"/>
          </el-form-item>
        </el-col>

        <el-col :span="8">
          <el-form-item label="scadenza minuti">
            <el-date-picker v-model="minute.expiration_minute"  readonly="readonly" type="date" placeholder="Pick a date" style="width: 100%"/>
          </el-form-item>
        </el-col>
      </el-row>
    </el-card>
  </el-form>
</template>

<script>
import { ref, toRaw } from "vue";
import { useRoute } from "vue-router";
import { reactive } from "vue";
import { ElMessage } from 'element-plus';
import customerAPI from '../services/customerAPI.js';
import { Delete } from '@element-plus/icons-vue';
export default {
  setup() {
  const route = useRoute();
  let details = ref({});

  let minute= ref({
      id:"",
      minute:0,
      residual_minute:0,
      expiration_minute:new Date(),
      accepted:false,
      partner:{},
      customer:{}
    })

  const new_minutes= ref([{ }])

    const partnerID={
      partner_id: ""
    }


    let partner=reactive({
      id:"",
      company_name:"",
      piva:"",
      CF:"",
      SDI:"",
      PEC:"",
      mail:"",
      phone_number:"",
      IBAN:"",
      CAB:"",
      ABI:"",
      RID:"",
      provvigione:0,
      API_key:"",
      tariffa_simba:0,
    });


    const customer = reactive({
      id: "",
      name: "",
      surname: "",
      date_birth: "",
      CF: "",
      mail: "",
      address: "",
      street_number:"",
      city: "",
      cap: "",
      area: "",
      telephone_number: "",
      role: "customer",
      business_name: "",
      piva: "",
      sdi: "",
      pec: "",
      isAlive: true,
    });


    const loadDetails = async (id) => {
      try {
        const response = await customerAPI.getCustomer(id);
        customer.id = response.data.id;
        customer.name = response.data.name;
        customer.surname = response.data.surname;
        customer.date_birth = response.data.date_birth;
        customer.mail = response.data.mail;
        customer.CF = response.data.CF;
        customer.address = response.data.address;
        customer.street_number=response.data.street_number;
        customer.city = response.data.city;
        customer.cap = response.data.cap;
        customer.area = response.data.area;
        customer.telephone_number = response.data.telephone_number;
        customer.business_name = response.data.business_name;
        customer.piva = response.data.piva;
        customer.sdi = response.data.sdi;
        customer.pec = response.data.pec;
        customer.isAlive = response.data.isAlive;
        let part = await loadPartner();
        let obj = Object.assign({},part,{"customer_id": response.data.id});
        return obj;
      } catch (e) {
        console.log(e);
      }
    };

    loadDetails(route.params.id).then((x) => {
      details.value = x[0];
    });

    const loadPartner= async()=>{
      try {
        const res=await customerAPI.partner();
        const partnerRes= res.data;
        partner.id=partnerRes.id;
        partner.company_name=partnerRes.company_name
        partner.piva=partnerRes.piva
        partner.CF=partnerRes.CF
        partner.SDI=partnerRes.SDI
        partner.PEC=partnerRes.PEC
        partner.mail=partnerRes.mail
        partner.phone_number=partnerRes.phone_number
        partner.IBAN=partnerRes.IBAN
        partner.CAB=partnerRes.CAB
        partner.ABI=partnerRes.ABI
        partner.RID=partnerRes.RID
        partner.provvigione=partnerRes.provvigione
        partner.API_key=partnerRes.API_key
        partner.tariffa_simba=partnerRes.tariffa_simba
        partnerID.partner_id= partnerRes.id;
        let a = Object.assign({},{"partner_id": res.data.id});
        return a;
      } catch (e) {
        console.log(e);
      }
    };

    const loadMinutes= async () => {
        try {
       
        let obj = await loadDetails(route.params.id);
        const response = await customerAPI.getMinute_info(obj);
        minute.value.id= response.data.id;
        minute.value.minute= response.data.minute;
        minute.value.residual_minute= response.data.residual_minute;
        minute.value.expiration_minute=(response.data.expiration_minute);
        minute.value.partner=partner;
        minute.value.customer=customer;

        } catch(error) {
        console.log(error);
        }
    };

    const update = () => {
      updCustomer(toRaw(customer)).then((response) => {
        if (response.status === 200) {
          if (customer.isAlive == true) {
            ElMessage({
              message: "Customer aggiornato con successo!",
              type: "success",
            });
            console.log("Customer aggiornato con successo!");
          } else {
            ElMessage.error("Customer disattivato!");
            console.log("Customer disattivato!");
          }
        } else {
          console.log("errore inatteso");
        }
      });
    };

    const updCustomer = async (customer) => {
      let response = {};
      try {
        response = await customerAPI.updCustomer(customer);
        return response;
      } catch {
        console.log("error");
      }
    };

    const update_minute = () => {
        try {
            updMinute(toRaw(minute)).then((response) => {
                console.log(response.status)
                if (response.status === 200) {
                    
                    console.log("Minuti aggiornati con successo!");
                } else {
                console.log("errore inatteso");
                }
            });
            ElMessage({
                    message: "Minuti aggiornati con successo!",
                    type: "success",
                });

        } catch(error){
        console.log(error)
        ElMessage({
                    message: "Errore nell'aggiornamento",
                    type: "error",
                });
        }
    };

    const updMinute = async (minute) => {    
      let response = {};
      try {
          minute.customer= customer;
          console.log("minutiiiiii")
          console.log(minute)
          response = await customerAPI.updMinute(minute);
          return response;

      } catch(error) {
        console.log(error);
      }
    };

    

loadPartner();
loadMinutes();
    return {
      partnerID,
      customer,
      update,
      minute,
      loadMinutes,
      new_minutes,
      update_minute,
      Delete
    };
  },
};
</script>

<style>
.el-card {
  margin-top: 6px;
}
.el-row {
  margin-bottom: -10px;
}
.salva {
  margin-top: -40px;
  margin-bottom: 10px;
}
</style>
