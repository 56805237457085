<template>
 <h1>NUOVO CLIENTE</h1>
  <el-form ref="form" :model="customer"  :rules="formRule" >
    <el-row :gutter="50">
      <el-col :sm="8" :xs="24"  >
        <el-form-item label="Nome" prop="name">
          <el-input v-model="customer.name"  :disabled="type === 0" />
        </el-form-item>
      </el-col>
      <el-col :sm="8" :xs="24">
        <el-form-item label="Cognome" prop="surname" >
          <el-input v-model="customer.surname"  :disabled="type === 0"/>
        </el-form-item>
      </el-col>
      <el-col :sm="8">
        <el-form-item label="Data di nascita" prop="date_birth" >
          <!-- <el-input v-model="customer.date_birth" :value="customer.date_birth" /> -->
          <el-date-picker
            v-model="customer.date_birth"
            type="date"
            placeholder="Pick a date"
            style="width: 100%"
            format="DD/MM/YYYY"
            :disabled="type === 0"
          />
        </el-form-item>
      </el-col>
    </el-row>
    <el-row :gutter="50">
      <el-col :sm="6" :xs="24" >
        <el-form-item label="Mail" prop="mail" >
          <el-input v-model="customer.mail"  :disabled="type === 0"/>
        </el-form-item>
      </el-col>
      <el-col :sm="6" :xs="24">
        <el-form-item label="Numero di telefono"  prop="telephone_number" >
          <el-input v-model="customer.telephone_number"  :disabled="type === 0"/>
        </el-form-item>
      </el-col>
      <el-col :sm="6" :xs="24">
        <el-form-item label="CF" prop="CF" >
          <el-input v-model="customer.CF"  :disabled="type === 0"/>
        </el-form-item>
      </el-col>
      <el-col :sm="6" :xs="24">
        <el-form-item label="P.iva">
          <el-input v-model="customer.piva" />
        </el-form-item>
      </el-col>
    </el-row>
    <el-row :gutter="50">
      <el-col :sm="6" :xs="24">
        <el-form-item label="Indirizzo" prop="address" >
          <el-input v-model="customer.address" :disabled="type === 0" />
        </el-form-item>
      </el-col>
      <el-col :sm="3" :xs="10">
        <el-form-item label="N. civico" prop="street_number" >
          <el-input v-model="customer.street_number" :disabled="type === 0" />
        </el-form-item>
      </el-col>
      <el-col :sm="5" :xs="24">
        <el-form-item label="Città" prop="city">
          <el-input v-model="customer.city"  :disabled="type === 0"/>
        </el-form-item>
      </el-col>
      <el-col :sm="5" :xs="24">
        <el-form-item label="CAP" prop="cap">
          <el-input v-model="customer.cap"  :disabled="type === 0"/>
        </el-form-item>
      </el-col>
      <el-col :sm="4" :xs="24" >
        <el-form-item label="Provincia" prop="area" >
          <el-input v-model="customer.area"  :disabled="type === 0"/>
        </el-form-item>
      </el-col>
    </el-row>

    <el-row :gutter="50">
      <el-col :sm="5" :xs="24">
        <el-form-item label="Ragione sociale">
          <el-input v-model="customer.business_name" />
        </el-form-item>
      </el-col>

      <el-col :sm="5" :xs="24">
        <el-form-item label="SDI">
          <el-input v-model="customer.sdi" />
        </el-form-item>
      </el-col>

      <el-col :sm="5" :xs="24">
        <el-form-item label="pec">
          <el-input v-model="customer.pec" />
        </el-form-item>
      </el-col>
      <el-col :sm="8" :xs="24">
        <el-form-item label="IBAN">
          <el-input v-model="customer.iban" />
        </el-form-item>
      </el-col>
    </el-row>
    <el-divider />
    <h4>Aggiungere auto al cliente</h4>
    <div
      v-for="(car, counter_car) in customer.cars"
      v-bind:key="counter_car"
    >
      <el-form ref="form2" :model="car"  :rules="formRuleCar" >
        <el-row> 
          <el-col :span="8" style="margin-top: 50px">
            <el-form-item label="Intestatario" prop="intestatario">
              <el-input v-model="car.intestatario" :disabled="type === 0" />
            </el-form-item>
          </el-col> 
        </el-row>
        <el-row :gutter="20"> 
          <el-col :span="5">
            <el-form-item label="Marca" prop="marca">
              <el-input v-model="car.marca" :disabled="type === 0" />
            </el-form-item>
          </el-col> 
          <el-col :span="5">
            <el-form-item label="Modello" prop="modello">
              <el-input v-model="car.modello" :disabled="type === 0" />
            </el-form-item>
          </el-col> 
          <el-col :span="5">
            <el-form-item label="Targa" prop="targa">
              <el-input v-model="car.targa" :disabled="type === 0" />
            </el-form-item>
          </el-col> 
          <el-col :span="5">
            <el-form-item label="Telaio">
              <el-input v-model="car.telaio" />
            </el-form-item>
          </el-col> 
          <el-col :sm="12" :xs="24" >
            <el-form-item label="Selezionare tipo di cambio" prop="transmission">
              <el-cascader
                v-model="car.transmission"
                :options="options"
                :props="props1"
                clearable
                :disabled="type === 0" 
                @change="handleChange"
              />
            </el-form-item>
          </el-col>
        </el-row>
        <el-button v-if="counter_car != 0"
          style="margin-top: 15px" 
          type="danger"
          :icon="Delete"
          @click="rimuoviCar($event, counter_car)" circle/>

        <el-button style="margin-top: 15px" @click="aggiungiCar" type="success" plain>Aggiungi auto</el-button>    
      </el-form>
    </div>
    <el-divider />
    <h4> aggiungi minuti </h4>
    <div>
      <el-form ref="form3" :model="customer.minutes[0]"  :rules="formNameMin" >
        <el-row :gutter="50">
          <el-col :sm="8" :xs="24">
            <!--<el-form-item label="minuti" prop="minute">
              <el-input v-model="customer.minutes[0].minute" :disabled="type === 0"/>
            </el-form-item>-->
            <el-form-item label="Selezionare valet minuti" prop="minute">
              <el-cascader
                v-model="customer.minutes[0].minute"
                :options="minute_options"
                :props="props1"
                clearable
                @change="handleChange_minute"
              />
            </el-form-item>
          </el-col>
          <!--<el-col :sm="8" :xs="24">
            <el-form-item label="scadenza minuti" prop="expiration_minute">
              <el-date-picker v-model="customer.minutes[0].expiration_minute"  :disabled="type === 0" type="date" placeholder="Pick a date" style="width: 100%" />
            </el-form-item>
          </el-col>-->
        </el-row>
      </el-form> 
    </div>
    <el-col :sm="6" :offset="10" >
      <el-form-item>
        <router-link class="routerLink" to="/home">
          <el-button style="margin: 5px " @click="annulla"> Annulla </el-button>
        </router-link>
        <el-button style="margin: 5px" @click="onSubmit('form','form2','form3')"> Create </el-button>
      </el-form-item>
    </el-col>
  </el-form>
</template>

<script>
//import { useRoute, useRouter } from 'vue-router';
import { reactive, toRaw, ref } from "vue";
import { ElMessage } from 'element-plus';
import { Delete } from '@element-plus/icons-vue';
import customerAPI from '../services/customerAPI';
const dayjs = require("dayjs");

export default {
  setup() {
    const props1 = {
      //checkStrictly: true,
       expandTrigger: 'hover'
    };

    let formRuleCar=reactive({
        intestatario:[
          {required: true, message: 'Inserire intestatario', trigger: 'blur' },
        ],
        marca:[
          {required: true, message: 'Inserire marca', trigger: 'blur' },
        ], 
        modello:[
          {required: true, message: 'Inserire modello', trigger: 'blur' },
        ], 
        targa:[
          {required: true, message: 'Inserire targa', trigger: 'blur' },
        ], 
        trasmission:[
          {required: true, message: 'Scegliere cambio', trigger: 'blur' },
        ], 
    })

    let formNameMin=reactive({
      minute:[
        {required: true, message: 'Inserire il numero di minuti', trigger: 'blur' },
      ],
      expiration_minute:[
        { type: 'date', required:true, message: 'Inserire scadenza minuti', trigger: 'blur' },
      ], 
    })

    let formRule = reactive({
      name: [
        { required: true, message: 'Inserire il nome', trigger: 'blur' },
      ],
        surname:  [
        { required: true, message: 'Inserire il cognome', trigger: 'blur' },
      ],
        date_birth:  [
        { type: 'date', required: true, message: 'Inserire data di nascita', trigger: 'blur' }
      ],
        mail:  [
        { required: true, message: 'Inserire mail', trigger: 'blur' },
      ],
        address: [
        { required: true, message: 'Inserire indirizzo', trigger: 'blur' },
      ],
      street_number: [
        { required: true, message: 'Inserire N.civico', trigger: 'blur' },
      ],
        city:  [
        { required: true, message: 'Inserire città', trigger: 'blur' },
      ],
        cap:  [
        { required: true, message: 'Inserire cap', trigger: 'blur' },
      ],
        area:  [
        { required: true, message: 'Inserire provincia', trigger: 'blur' },
      ],
        telephone_number:  [
        { required: true, message: 'Inserire numero di telefono', trigger: 'blur' },
      ],
        CF:  [
        { required: true, message: 'Inserire codice fiscale', trigger: 'blur' },
        { min: 16, max: 16, message: 'Numero di cifre errato', trigger: 'blur' },
      ],
   });

    let transmission = ref("");

    const options = [
      { value: "manual", label: "manuale" },
      { value: "automatic", label: "automatico" }
    ];

    let minute=ref("");
        
    const minute_options = [
      { value: "40", label: "Valet 1, 40 min" },
      { value: "80", label: "Valet 2, 80 min" },
      { value: "120", label: "Valet 3, 120 min" }
    ];

    const handleChange = (val) => {
      transmission.value = val;
    };

    const handleChange_minute = (val) => {
      //customer.minutes[0].minute=val;
      minute.value = val;
    };

    const customer = reactive({
      name: "",
      surname: "",
      date_birth: "",
      mail: "",
      address: "",
      street_number: "",
      city: "",
      cap: "",
      area: "",
      telephone_number: "",
      role: "customer",
      business_name: "",
      piva: "",
      sdi: "",
      pec: "",
      iban: "",
      device_token:null,
      isAlive: true,
      CF:"",
      cars:[{
      intestatario: "",
      marca:"",
      modello:"",
      telaio:"",
      transmission: transmission.value,
      }],
      minutes:[{
        minute:minute.value,
        residual_minute:0,
        expiration_minute:"",
        accepted:false,
        data: new Date(),
        partner:{}
        //customer:{}
      }]
    });

    const cars=[];
    
    // Funzioni per aggiungere nuovo
    const addcustomer_data = async (customer) => {
      let response = {};
      try {
         let response_minute= await customerAPI.partner();

          customer.minutes[0].partner=response_minute.data;
          customer.minutes[0].residual_minute=customer.minutes[0].minute;

          customer.name=customer.name.charAt(0).toUpperCase()+customer.name.slice(1).toLowerCase();
          customer.surname=customer.surname.charAt(0).toUpperCase()+customer.surname.slice(1).toLowerCase();

          customer.date_birth= dayjs(customer.date_birth).format("YYYY-MM-DD HH:mm:ss")
          const today= new Date();

          if(customer.minutes[0].minute==40) 
            {customer.minutes[0].expiration_minute= dayjs(today).add(1, 'year');}
          else if(customer.minutes[0].minute==80) 
            {customer.minutes[0].expiration_minute= dayjs(today).add(2, 'year');}
          else if(customer.minutes[0].minute==120) 
            {customer.minutes[0].expiration_minute= dayjs(today).add(3, 'year');}
          response = await customerAPI.addCustomer_byEmployee(customer);
          return response;
      } catch(error) {
        console.log(error);
      }
    };

    const rimuoviCar = (e, counter) => {
      customer.cars.splice(counter, 1);
      e.preventDefault();
    };

    const aggiungiCar = (e) => {
      customer.cars.push({

        intestatario: "",
        marca:"",
        modello:"",
        telaio:"",
        transmission:transmission.value,
      
      });
      e.preventDefault();
    };

    return {
      cars,
      formRule,
      addcustomer_data,
      customer,
      props1,
      options,
      //onSubmit,
      formRuleCar,
      formNameMin,
      handleChange,
      handleChange_minute,
      rimuoviCar,
      aggiungiCar,
      transmission,
      minute_options,
      Delete
    };
  },
 methods:{
     onSubmit(formName,formNamecar,formNameMin) {
  
       this.$refs[formName].validate((valid) => {
        this.$refs[formNamecar][0].validate((validcar)=>{
           this.$refs[formNameMin].validate((validmin)=>{
              if (validmin && validcar && valid) 
              { this.addcustomer_data(toRaw(this.customer)).then((response) => {
                    if (response.status === 200) { 
                      this.$router.push('/home');
                      ElMessage({
                            message: "Cliente aggiunto con successo!",
                            type: "success",
                          });
                    } else if (response.status === 400) {
                      this.$router.push('/addCustomer');
                      ElMessage({
                            message: "Errore!",
                            type: "error",
                          });
                      console.log("errore inatteso");
                    }
                  })} 
              else {console.log("erroree"); }
            })
          })       
        })
       }
  }
};
</script>

<style>
.el-col {
  margin-top: 4%;
}

.demo-date-picker .demonstration {
  display: block;
  color: var(--el-text-color-secondary);
  font-size: 14px;
  margin-bottom: 10px;
}
.demo-date-picker .block {
  padding: 30px 0;
  text-align: center;
  border-right: solid 1px var(--el-border-color);
  flex: 1;
}

.el-alert {
  margin: 120px 100px 100px;
}

.el-alert:first-child {
  margin: 50;
}
</style>
