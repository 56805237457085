import API from './API';

export default {
  partner() {
    return API().get('/employee/partners');
  },
  
  addCustomer(customer) {
    return API().post('/register', customer);
  },

  addCustomer_byEmployee(customer) {
    return API().post('/registerCustomer', customer);
  },

  getCustomer_all() {
    return API().get('/customer/getAll_employee');
  },

  addMinute(minute){
    return API().post('/minute', minute)
  },

  addCar(car){
    return API().post('/car/car', car)
  },

  getMinute(){
    return API().get('/minute/minuteAll')
  },

  getMinute_info(min) {
    return API().post('/minute/minute-info', min);
  },

  getPartners() {
    return API().get('/partner');
  },

  getCustomer(id) {
    return API().get('/customer/'+id);
  },

  updCustomer(customer) {
    return API().patch('/customer/' + customer.id, customer)
  },

};