import API from './API';

export default {
  headquarter() {
    return API().get('/employee/headquarters');
  },

  getBooking(id) {
    return API().get('/booking/info/' + id);
  },

  getBookingAttivi(id) {
    return API().get('/employee/attivi/' + id);
  },

  getBookingPassati(id) {
    return API().get('/employee/storico/' + id);
  },

  getBookingFuturi(id) {
    return API().get('/employee/futuri/' + id);
  },

  patchBooking(id) {
    return API().patch('/state', id);
  },
};