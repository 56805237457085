<template>

  <h1>CLIENTI</h1> <br> 
 Clicca quì per registrare un nuovo cliente
          <router-link class="routerLink" to="/addcustomer">
            <el-icon style="vertical-align: middle" color="#409EFC" :size="20" @click="addDriverClick()"
              ><CirclePlus
            /></el-icon>
          </router-link>
  
<br><br><br><br>
  <h2>Clienti in attesa di conferma</h2>
  <div class="top">
    <el-input v-model="input" placeholder="Inserisci il nome del customer">
      <template #append>
        <el-button>
          <el-icon><search /></el-icon>
        </el-button>
      </template>
    </el-input>
    <el-table :data="filteredTableNotAlive" style="width: 100%" :header-cell-style="{ background: 'rgb(231,228,58)'}">
      <el-table-column prop="id" label="id cliente">
        <template #default="scope">
          <el-input :value="scope.row.id" />
        </template>
        </el-table-column>
      <el-table-column prop="name" label="nome" />
      <el-table-column prop="min_id" label="id minuti" >
        <template #default="scope">
          <el-input :value="scope.row.min_id" />
        </template>
        </el-table-column>
      <el-table-column prop="mail" label="mail" />
      <el-table-column label="dettagli">
        <template #default="scope">
          <router-link
            :to="{ path: `/details/customerAlive/${scope.row.id}` }"
            class="routerLink"
          >
            <el-button size="small" @click="handleClick(scope.row.min_id)">
              Visualizza
            </el-button>
          </router-link>
        </template>
      </el-table-column>
    </el-table>
  </div>
  <br><br><br><br>
  
    <h2>Tutti i clienti</h2>
    <br/>
    <el-button style="margin-left:80%" @click="downloadExcel">Scarica Excell clienti Valet finanziamento</el-button>
    <div class="top">
      <el-input v-model="input2" placeholder="Inserisci il nome del customer">
        <template #append>
          <el-button>
            <el-icon><search /></el-icon>
          </el-button>
        </template>
      </el-input>
    <el-table :data="filteredTableAlive" style="width: 100%" :header-cell-style="{ background: 'rgb(231,228,58)'}">
      <el-table-column prop="id" label="id cliente">
        <template #default="scope">
          <el-input :value="scope.row.id" />
        </template>
      </el-table-column>
      <el-table-column prop="name" label="nome" />
      <el-table-column prop="mail" label="mail" />
      <el-table-column prop="data" label="data di registrazione" />
      <el-table-column label="dettagli">
        <template #default="scope">
          <router-link :to="{ path: `/details/customerAlive/${scope.row.id}` }" class="routerLink">
            <el-button size="small" @click="handleClick(scope.row.id)">
              Visualizza
            </el-button>
          </router-link>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
import { ref, computed } from "vue";
import customerAPI from "../services/customerAPI";
const dayjs = require("dayjs");
import ExcelJS from "exceljs";

export default {
  setup() {
    const value = ref(0);
    const input = ref("");
    const input2 = ref("");
    let tableDataAlive = ref([]);
    let tableDataNotAlive = ref([]);

    let tableDataMinute= ref([]);

    const downloadExcel = () =>{
      // Call a function to generate an Excel file from the data in tableDataMinute
      generateExcel(tableDataMinute.value);
    }

    const generateExcel= (data) =>{
      const workbook = new ExcelJS.Workbook();
      const worksheet = workbook.addWorksheet("Clienti Minute");

      // Define the columns
      worksheet.columns = [
        { header: "ID cliente", key: "id" },
        { header: "Nome", key: "name" },
        { header: "Mail", key: "mail" },
        { header: "Data di Registrazione", key: "data" },
      ];

      // Add the data
      data.forEach((row) => {
        worksheet.addRow({
          id: row.id,
          name: row.name,
          mail: row.mail,
          data: row.data,
        });
      });

      // Generate the Excel file
      workbook.xlsx.writeBuffer().then((excelBuffer) => {
        const blob = new Blob([excelBuffer], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" });
        const url = window.URL.createObjectURL(blob);

        // Create a download link and trigger a click to download the file
        const a = document.createElement("a");
        a.href = url;
        a.download = "clienti_minute.xlsx";
        a.click();

        window.URL.revokeObjectURL(url);
      });
    }

    const loadCustomers = async () => {
      try {
        tableDataNotAlive.value=[];
        tableDataAlive.value=[];
        tableDataMinute.value=[];
        const response= await customerAPI.getCustomer_all();

        response.data.forEach((customer) => {
          customer.forEach((customer_detail) => {
            
            if (customer_detail.minutes.length != 0 && customer_detail.minutes[0].accepted === false) {
              let tableRow = {
                id: customer_detail.id,
                name: customer_detail.name + ' ' + customer_detail.surname,
                mail: customer_detail.mail,
                min_id: customer_detail.minutes[0].id,
                accepted: customer_detail.minutes[0].accepted
                
              };
              tableDataNotAlive.value.push(tableRow);
            } else {
              let tableRowAlive = {
                id: customer_detail.id,
                name: customer_detail.name + ' ' + customer_detail.surname,
                mail: customer_detail.mail,
                data: dayjs(customer_detail.data_reg).format("DD/MM/YY HH:mm"),
                accepted: 'false'
              };
              tableDataAlive.value.push(tableRowAlive);
            } 

            if (customer_detail.minutes.length != 0 && customer_detail.minutes[0].accepted === true) {
              tableDataMinute.value.push({id: customer_detail.id,
                name: customer_detail.name + ' ' + customer_detail.surname,
                mail: customer_detail.mail,
                data: dayjs(customer_detail.data_reg).format("DD/MM/YY HH:mm"),})

            }
            
          })
          
        })
        console.log(tableDataMinute.value)
      } catch (e) {
        console.log(e);
      }
    };

    loadCustomers();

    const filteredTableNotAlive = computed(function () {
      return tableDataNotAlive.value.filter((row) =>
        row.name.toLowerCase().includes(input.value.toLowerCase())
      );
    });

    const filteredTableAlive = computed(function () {
      return tableDataAlive.value.filter((row) =>
        row.name.toLowerCase().includes(input2.value.toLowerCase())
      );
    });

    return {
      downloadExcel,
      input,
      input2,
      value,
      tableDataAlive,
      tableDataNotAlive,
      filteredTableAlive,
      filteredTableNotAlive,
    };
  },
};
</script>

<style>
.top {
  margin-top: 30px;
}
</style>
