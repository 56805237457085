<template>
  <div style="margin-top: 40px">
    <h1>Dettagli del booking {{ booking.date }}</h1>
  </div>

  <hr
    style="
      height: 4px;
      color: rgb(231,228,58);
      background-color: rgb(231,228,58);
      width: 35%;
      margin-top: 15px;
      margin-left: 32.5%;
    "
  >

  <div class="marg_form" justify="center" style="margin-left: 150px; margin-right: 150px; margin-top: 30px">
    <el-row type="flex">
      <el-col :span="3" style="margin-top: 52px; ">
        <span>Cliente</span>
      </el-col>
      <el-col :span="4">
        <el-input v-model="booking.customer" readonly="readonly" />
      </el-col>
<el-col :span="3" style="margin-top: 52px; margin-left: 10px">
        <span>Cell. cliente</span>
      </el-col>
      <el-col :span="4">
        <el-input v-model="booking.customer_number" readonly="readonly" />
      </el-col>
    </el-row>
    <el-row type="flex">
      <el-col :span="3" style="margin-top: 52px;">
        <span>Auto</span>
      </el-col>
      <el-col :span="4">
        <el-input v-model="booking.auto" class="w-50 m-2" readonly="readonly" />
      </el-col>

      <el-col :span="3" style="margin-top: 52px; margin-left: 10px">
        <span>Targa</span>
      </el-col>
      <el-col :span="4">
        <el-input v-model="booking.targa" class="w-50 m-2" readonly="readonly" />
      </el-col>
    </el-row>

    <el-row type="flex" style="margin-top: 5%">
      <el-col :span="4" style="margin-top: 10px; margin-left: 15px">
        <el-icon> <LocationInformation /></el-icon>
        <span>Punto di prelievo</span>
      </el-col>
      <el-col :span="7" style="margin-top: 5px; margin-left: 5px">
        <el-input v-model="booking.pick_point" readonly="readonly" />
      </el-col>
      <el-col :span="6" style="margin-top: 5px">
        minuti andata: {{booking.min_andata}}
      </el-col>
    </el-row>

    <el-row type="flex" style="margin-top: 3%">
      <el-col :span="4" style="margin-top: 10px; margin-left: 15px">
        <el-icon><OfficeBuilding /></el-icon>
        <span>Sede del Partner</span>
      </el-col>
      <el-col :span="7" style="margin-top: 5px; margin-left: 5px">
        <el-input v-model="booking.partner_point" readonly="readonly" />
      </el-col>
    </el-row>

    <el-row type="flex" style="margin-top: 3%">
      <el-col :span="4" style="margin-top: 10px; margin-left: 20px">
        <el-icon> <LocationInformation /></el-icon>
        <span>Punto di consegna</span>
      </el-col>
      <el-col :span="7" style="margin-top: 5px;">
        <el-input v-model="booking.leave_point" readonly="readonly" />
      </el-col>
      <el-col :span="6" style="margin-top: 5px">
        minuti ritorno: {{booking.min_ritorno}}
      </el-col>
    </el-row>
<br>
    <el-divider/>
    <el-row type="flex" style="margin-top: 3%; margin-left: 2%">
      <el-col :span="3" style="margin-top: 10px">
        <span>Driver andata</span>
      </el-col>
      <el-col :span="4" style="margin-top: 5px">
        <el-input v-model="booking.driver_andata" readonly="readonly" />
      </el-col>
      <el-col :span="3" style="margin-top: 10px; margin-left: 40px">
        <span>Telefono driver</span>
      </el-col>
      <el-col :span="4" style="margin-top: 5px">
        <el-input v-model="booking.telephone_gone_driver" readonly="readonly" />
      </el-col>
    </el-row>

    <el-row type="flex" style="margin-top: 5%; margin-left: 2%">
      <el-col :span="3" style="margin-top: 10px">
        <span>Driver ritorno</span>
      </el-col>
      <el-col :span="4" style="margin-top: 5px; ">
        <el-input v-model="booking.driver_ritorno" readonly="readonly" />
      </el-col>
      <el-col :span="3" style="margin-top: 10px; margin-left: 40px">
        <span>Telefono driver</span>
      </el-col>
      <el-col :span="4" style="margin-top: 5px">
        <el-input v-model="booking.telephone_return_driver" readonly="readonly" />
      </el-col>
    </el-row>

    <!--<el-row type="flex" style="margin-top: 5%">
      <el-col :span="3" style="margin-top: 10px">
        <span>Driver andata</span>
      </el-col>
      <el-col :span="4" style="margin-top: 5px">
        <el-input v-model="booking.driver_andata" readonly="readonly" />
      </el-col>
      <el-col :span="3" style="margin-top: 10px; margin-left: 40px">
        <span>Driver ritorno</span>
      </el-col>
      <el-col :span="4" style="margin-top: 5px">
        <el-input v-model="booking.driver_ritorno" readonly="readonly" />
      </el-col>
    </el-row>-->

    <el-row type="flex" style="margin-top: 3%">
      <el-col :span="14">
        <router-link class="routerLink" to="/">
          <el-button type="danger" size="large" round @click="onSubmit();" :disabled="booking.state != 'take_picture_atCustomer1' ? '' : disabled">
            Conferma consegna auto
          </el-button>
        </router-link>
      </el-col>
      <el-col :span="5">
        <router-link class="routerLink" to="/">
          <el-button type="danger" size="large" round @click="onSubmit()" :disabled="booking.state != 'driver_atPartner1' ? '' : disabled">
            Conferma ritiro auto
          </el-button>
        </router-link>
      </el-col>
    </el-row>

    <el-row type="flex" style="margin-top: 3%; margin-left: 30px">
      <el-col :span="24">
        <div class="demo-collapse">
          <el-collapse v-model="activeName" accordion>
            <!-- <el-collapse-item
              title="Cliccare per visualizzare i dettagli dei lavori da svolgere nel booking:"
              name="1"
            >-->
            <el-collapse-item
              name="1"
            >
              <template #title> 
                <div class="collapse-title">
                  Cliccare per visualizzare i dettagli dei lavori da svolgere nel booking
                </div>
              </template>
              
              <div v-for="(task, counter_min) in booking.tasks" v-bind:key="counter_min">
                <div v-if="task.pay == true" style="margin-bottom: 3%" >
                  <el-input v-model="task.description" autosize readonly="readonly" >
                    <template #append> {{ task.cost + " €" }} {{ "pagamento in app"}} </template>
                  </el-input>
                </div>
                <div v-if="task.pay == false" style="margin-bottom: 3%" >
                  <el-input v-model="task.description" autosize readonly="readonly">
                    <template #append> {{ task.cost + " €" }} {{ "pagamento NON in app"}} </template>
                  </el-input>
                </div>
              </div>
              <el-input v-model="booking.title" autosize readonly="readonly">
                <template #append> {{ booking.tot_cost + " €" }} </template>
              </el-input>
            </el-collapse-item>
          </el-collapse>
        </div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import { ref } from "vue";
import bookingAPI from "../services/bookingAPI.js";
import { useRoute } from "vue-router";
import { reactive } from "vue";
import { ElMessage } from "element-plus";
const dayjs = require("dayjs")
export default {
  name: "HomePage",
  components: {},
  setup() {
    let condition1 = false;
    let condition2 = true;
    const activeName = ref('1')
    const route = useRoute();
    const bookingId = route.params.id.split("-")[0];
    const tableData = ref([]);
    const booking = reactive({
      customer: "",
      customer_number:"",
      auto: "",
      targa: "",
      tasks: [],
      driver_andata: "",
      driver_ritorno: "",
      telephone_gone_driver:"",
      telephone_return_driver:"",
      title: "Costo totale dei lavori",
      tot_cost: 0,
      state: ""
    });

    const loadBooking = async () => {
      try {

        const response = await bookingAPI.getBooking(route.params.id);
        booking.date= dayjs(response.data.datePartner_gone).format('DD/MM/YYYY');
        booking.customer =
          response.data.customer.name + " " + response.data.customer.surname;
        booking.customer_number=
            response.data.customer.telephone_number;
        booking.auto = response.data.car.marca + ", " + response.data.car.modello;
        booking.targa = response.data.car.targa;
        booking.driver_andata =
          response.data.gone_driver.name + " " + response.data.gone_driver.surname;
        booking.driver_ritorno =
          response.data.return_driver.name + " " + response.data.return_driver.surname;
        booking.telephone_gone_driver=
             response.data.gone_driver.telephone_number;
        booking.telephone_return_driver=
            response.data.return_driver.telephone_number;
        booking.tasks = response.data.tasks;
        booking.state = response.data.state;
        booking.tasks.forEach((task) => {
          booking.tot_cost = booking.tot_cost + task.cost;
          booking.min_andata=response.data.simba_minuteGone ;
          booking.min_ritorno=response.data.simba_minuteReturn ;
          booking.partner_point=
            response.data.headquarter.address+ " "+  response.data.headquarter.street_number+ ", "+ response.data.headquarter.cap+ " "+ response.data.headquarter.city + " "+ response.data.headquarter.province;
          
          if(response.data.points[0].pick_point == true) 
          { 
            //console.log("pointsss", response.data.points[0])
            booking.pick_point = response.data.points[0].address+ " " + 
                                 response.data.points[0].street_number + ", "+
                                 response.data.points[0].cap + " "+
                                 response.data.points[0].city + " "+
                                 response.data.points[0].province;

            booking.leave_point = response.data.points[1].address+ " " + 
                                 response.data.points[1].street_number + ", "+
                                 response.data.points[1].cap + " "+
                                 response.data.points[1].city+ " "+
                                 response.data.points[1].province;
          }else{
            
            booking.leave_point = response.data.points[0].address+" "+
                                  response.data.points[0].street_number +", "+  
                                  response.data.points[0].cap + " "+
                                  response.data.points[0].city + " "+
                                  response.data.points[0].province;

            booking.pick_point = response.data.points[1].address+ " " + 
                                 response.data.points[1].street_number + ", "+
                                 response.data.points[1].cap + " "+
                                 response.data.points[1].city+ " "+
                                 response.data.points[1].province;

          }
          
        });
        
        console.log("booking.state",booking.state);
        return booking;
      } catch (e) {
        console.log(e);
      }
    };

    loadBooking();
    
    /*const onSubmit = () => {
      updBooking().then((response) => {
        if (response.status === 200) {
          ElMessage({
            message: "Stato del booking aggiornato!",
            type: "success",
          });
          console.log("Stato del booking aggiornato!");
        } else {
          console.log("errore inatteso");
        }
      });
    };*/

    const updBooking = async () => {
      let response = {};
      try {
        let data = {
          id: route.params.id,
          state: booking.state
        };
        response = await bookingAPI.patchBooking(data);

        return response;
      } catch (e) {
        console.log(e);
      }
    };

    const changeCondition = () => {
      condition1 = false;
    }

    return {
      tableData,
      bookingId,
      booking,
      loadBooking,
      //onSubmit,
      condition1,
      condition2,
      changeCondition,
      updBooking,
      activeName
    };
  },

  methods:{

     onSubmit(){
      this.updBooking().then((response) => {
        if (response.status === 200) {
          this.$router.go('/');
          ElMessage({
            message: "Stato del booking aggiornato!",
            type: "success",
          });
          console.log("Stato del booking aggiornato!");
        } else {
          console.log("errore inatteso");
        }
      });
    }
  }
};
</script>

<style>
.margin {
  margin-top: 30px;
}
hr {
  margin-top: 20px;
}
.marg_form {
  margin-top: 100px;
}
.el-input-group__append {
  background: transparent;
}

.collapse-title{
    font-size: 18px;
    font-weight: bold;
}
</style>
