import { defineStore } from 'pinia';
import { useStorage } from '@vueuse/core';

export const isLoggedStore = defineStore('logged', () => {
  const viewMenu = useStorage('viewMenu', false);
  const isLogged = useStorage('isLogged', false);
  const jwt= useStorage('jwt','');
  const refresh_token=useStorage('refresh_token','');
  const id= useStorage('id','');
  const role= useStorage('role','');

  return { viewMenu, isLogged, jwt, refresh_token, role, id};
});
