import axios from 'axios';


  /*export default (url = 'http://54.146.10.165:3000/') => { */

 
  export default (url = 'https://gestionalesimba.it/') => { 

 /*export default (url = 'http://44.201.196.104:3000/') => {*/
  return axios.create({
    baseURL: url,
    headers: { 
    'Access-Control-Allow-Origin': '*',  
    "Authorization": "Bearer " + localStorage.getItem("jwt") },
  });
};


