<template>

  <div
    style="
        width: 100%;
        height: 100vh;
        display: flex;
        justify-content: center;
        align-item: center;
        
      "
  >
    <div
      style="

        width: 400px;
        height: 275px;
        border: 2px solid #0C090A;
        border-radius: 20px;
        padding: 20px;
      "
    >
        <img
          style="width: 90px; height: 116px"
                  :src="require('../assets/logo-simba-RIDIMENSIONATO.png')"
                  class="image"
                />
      <el-form :model="loginData" label-width="100px">
        <el-form-item label="Nome utente">
          <el-input v-model="loginData.mail" />
        </el-form-item>
        <el-form-item label="Password">
          <el-input
            v-model="loginData.password"
            type="password"
            placeholder="Inserire password"
            show-password
          />
        </el-form-item>

        <el-button color="rgb(231,228,58)" :dark="true" plain @click="logIn">Log in!
        </el-button>

      </el-form>
      <router-link :to="{ path: `/reset-password` }" class="routerLink">
             <el-link style="margin-top: 11px " :underline="false">Hai dimenticato la password? clicca quì!</el-link>
               </router-link>
    </div>
  </div>

</template>

<script>
import { useRouter } from 'vue-router';
import { isLoggedStore } from '../store/logged';
import { reactive,toRaw } from 'vue';
import authAPI from '../services/authAPI.js';
import { ElMessage } from 'element-plus';

export default {
  name: 'LoginPage',
  components: {},
  setup() {
    const store = isLoggedStore();
    const router = useRouter();
    const loginData = reactive({
      mail: '',
      password: '',
    });



    const logIn_data = async (loginData) => {
      /* ... */
      try{
         const response = await authAPI.login(loginData);
         return response;
      }catch(error){
          console.log(error);
      }
    };

const logIn =()=>{
  logIn_data(toRaw( loginData) ).then((response) => {
      try{  if (response.status === 200) {
          store.id=response.data.id;
          store.jwt=response.data.access_token;
          store.role = response.data.role;
          store.refresh_token=response.data.refresh_token;
          
          if(response.data.role=="employee")
          {
            store.isLogged = true;
            store.viewMenu = true;
            router.push('/');
            }
          else if(response.data.role=="salesvendor")
          {
            store.isLogged = true;
            store.viewMenu = true;
            router.push('/home')
          }else {
             ElMessage({
                message: "Non sei autorizzato ",
                type: "Error",
              });
          console.log('Non sei autorizzato');
          }
         
        } else if(response.status === 401)  {
           ElMessage({
                message: "Non sei autorizzato ",
                type: "Error",
              });
          console.log('Non sei autorizzato');
        }
        else if (response.status === 404) {
           ElMessage({
                message: "Username o password errati",
                type: "Error",
              });
          console.log('errore inatteso');
        } else if (response.status === 400) {
           ElMessage({
                message: "Error",
                type: "Error",
              });
        }
      } catch(error){
           ElMessage({
                message: "Username o password errati",
                type: "Error",
              });
          console.log('errore inatteso');
        
      }
      });
}

    return {
      loginData,
      logIn,
      store,

    };
  },
};
</script>
